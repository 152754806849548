import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/careers.css'
import {navigate} from 'gatsby'
import {Link} from 'gatsby'

import NotFound from "../images/404-img.png";
import Search from "../images/icon-career-location.png";
import Arrow from "../images/long-arrow-right.png";

class Careers extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        careers: [],
        departments: [],
        department: ["All"],
        search: '',
        location: 'All',
        load: false,
      }
    }

    filterLocation(loc){
      this.setState({location: loc})
    }

    filterDepartment(div){
      div = div['dept']
      var arrTemp = this.state.department
      if (this.state.department == 'All'){
        arrTemp.pop()
        this.setState({department: arrTemp})
        this.changeClass('All')
      }
      if (div == 'All'){
        arrTemp = ['All']
      } else{
        if (arrTemp.includes(div)){
          arrTemp = arrTemp.filter(function(item){return item != div})
        } else{
          arrTemp.push(div)
        }
      }
      if (arrTemp.length == 0){
        arrTemp = ["All"]
        this.setState({department: arrTemp})
        this.changeClass('All')
      } else{
        this.setState({department: arrTemp})
        this.changeClass(div)
      }
    }

    changeClass(div){
      if (div == 'All'){
        document.getElementById(div).classList.toggle("filter-department");
        var listDiv = this.state.department
        for(var idx in listDiv){
          document.getElementById(listDiv[idx]).classList.toggle("filter-department-selected");
        }
      }
      document.getElementById(div).classList.toggle("filter-department-selected");
    }

    searchJob(){
      this.setState({search: document.getElementById("car-search-bar").value})
    }

    fetchDepartments(){
      fetch("https://tania-cms.azurewebsites.net/departments")
        .then(response => response.json())
        .then(data => {
            var arrTemp = this.state.departments
            for(var idx in data){
              arrTemp.push(data[idx]["department_name"])
              this.setState({departments: arrTemp})
            }
            if(this.props.location.state != null){
              if(this.props.location.state.jobdepartment != null){
                this.filterDepartment({dept: this.props.location.state.jobdepartment})
              }
            }
          }
        )
      
    }

    fetchJobs(){
      fetch("https://tania-cms.azurewebsites.net/jobs")
        .then(response => response.json())
        .then(data => {
          console.log(data)
          var arrTemp = this.state.careers
          for(var idx in data){
            if(data[idx]["active"]){
              var descTemp = (data[idx]["job_description"].split('- ')).filter((data) => data != "")
              var reqTemp = (data[idx]["job_requirements"].split('- ')).filter((data) => data != "")
              arrTemp.push({"id": data[idx]["id"], position: data[idx]["job_title"], department: data[idx]["department"]["department_name"], location: data[idx]["location"]["location_name"], jobDescription: descTemp, jobRequirement: reqTemp})
            }
          }
          this.setState({careers: arrTemp})
          this.setState({load: true})
        })
    }

    componentDidMount(){
      this.fetchDepartments()
      this.fetchJobs()
    }

    render(){

        var listdepartment = this.state.department
        var departments = this.state.departments
        var filteredCareers = this.state.careers
        var location = this.state.location
        var searchValue = this.state.search
        var regex = new RegExp("("+ searchValue + ")", "i")

        if(listdepartment!= 'All'){
          var exp = ''
          for (var idx in listdepartment){
            if (idx == 0){
              exp += 'job.department == "' + listdepartment[idx] + '"'
            } else {
              exp += ' || job.department == "' + listdepartment[idx] + '"'
            }
          }
          filteredCareers = filteredCareers.filter(function(job){return eval(exp)})
        }
        if(location != 'All'){
          filteredCareers = filteredCareers.filter(function(job){return job.location == location})
        }
        if(searchValue != ''){
          filteredCareers = filteredCareers.filter(function(job){return job.position.match(regex)})
        }

        return(

            <Layout>
                <Head title = "Careers"/>
                <div id = "car-main-container">
                    
                    <div id = "car-title">

                        <div id = "car-title-sub">Join Our Team</div> 
                        <div id = "car-title-primary">Find Your Purpose</div>
                        <div id = "car-title-desc">Create an impact, solve most pressing social and environmental problems, and make the world a better place.</div>
                    
                    </div>

                    <div id = "car-filter">

                        <div id = "car-filter-container-search">

                          <div id = "car-dropdown">
                            <button id = "car-input-location">
                              <img id = "location-icon" src = {Search}/>
                              &nbsp;&nbsp;&nbsp;{this.state.location}
                            </button>
                            <div id = "car-dropdown-content">
                            <div class = "location-option" onClick = {() => this.filterLocation('All')}>All</div>
                              <div class = "location-option" onClick = {() => this.filterLocation('Bandung')}>Bandung</div>
                              <div class = "location-option" onClick = {() => this.filterLocation('Remote')}>Remote</div>
                            </div>
                          </div>

                          <input id = "car-search-bar" type = "text" placeholder = "         Type Your Keywords"/>

                          <input id = "car-search-btn" type = "submit" value = "Search" onClick = {() => this.searchJob()}/>
                        
                        </div>

                        <div id = "car-filter-container-department">
                          <div id = "All" class = "filter-department-selected" onClick={() => this.filterDepartment({dept: 'All'})}>All</div>
                          
                          {departments.map((dept) => (
                            <div id = {dept} class = "filter-department" onClick={() => this.filterDepartment({dept})}>{dept}</div>
                          ))}

                        </div>
                    
                        </div>

                    <div id = "car-content">

                      {this.state.load != true && <div id = "not-found-msg">Loading...</div>}
                      {(this.state.load && filteredCareers.length == 0) && 
                          
                          <div id = "not-found-container">
                            
                            <img id = "not-found-img" src = {NotFound} alt = "not-found-img"/>
                        
                            <div id = "not-found-msg">Oops, we can't find the jobs you are looking for</div>
                          
                          </div>
                        
                      }
                        
                      {departments.map((dept) => (

                        <div>

                        {(filteredCareers.filter(function(job){return job.department == dept})).length != 0 && <div>
                            
                          <div id = "car-content-title">{dept}</div>
                          <div id = "car-content-sub-title">{dept} department vacancies at Neurafarm</div>

                          <div id = "car-content-list">
                          
                          {(filteredCareers.filter(function(job){return job.department == dept})).map((career) => (
                            
                            <div class = "car-content-item" onClick={() => 
                              navigate("/careers-detail", {
                                state: {
                                  jobId : career.id,
                                  jobPosition: career.position,
                                  jobdepartment: career.department,
                                  jobLocation: career.location,
                                  jobDescription: career.jobDescription,
                                  jobRequirement: career.jobRequirement
                                }})}>
              
                              <div class = "item-position">{career.position}</div>
                  
                              <div>
                                <img class = "item-icon" src = "https://i.ibb.co/RbCN9Jv/work-2.png" alt = "department-img"/><span class = "item-info">{career.department}</span>
                                <img class = "item-icon" src = "https://i.ibb.co/3dH5FsW/pin-2.png" alt = "location-img"/><span class = "item-info">{career.location}</span>
                              </div>

                              <hr class = "item-divider"></hr>

                              <Link
                                className = "car-link-container" 
                                to={"/careers-detail"}
                                state={{
                                  jobId : career.id,
                                  jobPosition: career.position,
                                  jobdepartment: career.department,
                                  jobLocation: career.location,
                                  jobDescription: career.jobDescription,
                                  jobRequirement: career.jobRequirement
                                }}
                              >
                                Learn More
                                <img id = "arrow-icon" src = {Arrow} class="arrow-logo"/>
                              </Link>
                            
                            </div>

                          ))}

                          </div>

                        </div>}

                        </div>

                      ))}

                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default Careers;